import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CategoryType } from '../common/category-model';
import { createCategoryService, 
        deleteCategoryService, 
        editCategoryService, 
        getAllMyCategoriesService, 
        getAllMyKidsCategoriesService, 
        getCategoriesKidsPaginatedService, 
        getCategoryDetailsService, 
        getMyCategoriesService, 
        resetCategoryService} 
        from '../services/categoryAxiosServices';
import { RootState } from './store';


interface CategoriesType {
    allCategories: CategoryType[];
    allCategoriesNoPaginate: Pick <CategoryType, "categoryName" | "categoryId" | "userId" | "sedToMe" | "marketAccessible" | "marketApprovement" | "adopted" | "categoryType">[];
    counts: number | null;
    pages: number;
    categoryDetails: CategoryType;
    loadingCategoryDetails: boolean;
    errorCategoryDetails: string | undefined;
    loadingCategories: boolean;
    errorGetCategories: string | undefined;
    errorCreateCategories: string | undefined;
    errorEditCategories: string | undefined;
    loadingCreateCategory: boolean
    loadingEditCategory: boolean;
    loadingDelete: boolean
    errorDelete: string | undefined;
}

export const initialState: CategoriesType = {
    allCategories: [],
    allCategoriesNoPaginate: [],
    counts: null,
    pages: 0,
    categoryDetails: {
        categoryName: "",
        categoryId: "",
        userId: "",
        cardsLength: 0,
        learnedCardsVerbalLength: 0,
        learnedCardsTypingLength: 0,
        totalGuestsVerbal: 0,
        totalNotGuestsVerbal: 0,
        totalGuestsTyping: 0,
        totalNotGuestsTyping: 0,
        categoryType: "",
    },
    loadingCategoryDetails: false,
    errorCategoryDetails: undefined,
    loadingCategories: false,
    errorGetCategories: undefined,
    errorCreateCategories: undefined,
    errorEditCategories: undefined,
    loadingCreateCategory: false,
    loadingEditCategory: false,
    loadingDelete: false,
    errorDelete: undefined
}

interface GetCategoryType {
    userId: string;
    aiCreated: boolean;
    sortBy: string;
    sortOrder: string;
    page: number
}

export const getAllCategories = createAsyncThunk('categories/getAllCategories', async ({userId, aiCreated, page, sortBy, sortOrder}: GetCategoryType) => {
    let response;
    try {
        response = await getMyCategoriesService(userId, aiCreated, page, sortBy, sortOrder)
    } catch(error) {
        console.error(error)
        throw error;
    }
    return response?.data
})

export const getCategoriesKidsPaginated = createAsyncThunk('categories/getCategoriesKidsPaginated', async ({userId, aiCreated, page, sortBy, sortOrder}: GetCategoryType) => {
    let response;
    try {
        response = await getCategoriesKidsPaginatedService(userId, aiCreated, page, sortBy, sortOrder)
    } catch(error) {
        console.error(error)
        throw error;
    }
    return response?.data
})

export const getAllCategoriesNoPaginate = createAsyncThunk('categories/getAllCategoriesNoPaginate', async (userId: string) => {
    let response;
    try {
        response = await getAllMyCategoriesService(userId)
    } catch(error) {
        console.error(error)
        throw error;
    }
    return response?.data
})

export const getAllKidsCategoriesNoPaginate = createAsyncThunk('categories/getAllKidsCategoriesNoPaginate', async (userId: string) => {
    let response;
    try {
        response = await getAllMyKidsCategoriesService(userId)
    } catch(error) {
        console.error(error)
        throw error;
    }
    return response?.data
})

export const createNewCategory = createAsyncThunk('categories/createNewCategory', async ({
    categoryName, 
    userId, 
    categoryType
}: Pick<CategoryType, "categoryName" | "userId" | "categoryType">) => {
    try {
        await createCategoryService({categoryName, userId, categoryType})
    } catch(error) {
        console.error(error)
        throw error;
    }
})

export const editCategory = createAsyncThunk('categories/editCategory', async ({
    categoryId, 
    categoryName, 
    userId,
    categoryType
}: Pick<CategoryType, "categoryName" | "userId" | "categoryId" | "categoryType">) => {
    try {
        await editCategoryService({categoryId, categoryName, userId, categoryType})
    } catch(error) {
        console.error(error)
        throw error
    }
})

export const deleteCategory = createAsyncThunk('categories/deleteCategory', async(categoryId: string) => {
    try {
        await deleteCategoryService(categoryId)
    } catch(error) {
        console.error(error)
        throw error
    }
})

export const getCategory = createAsyncThunk('categories/getCategory', async (categoryId: string) => {
    let response;
    try {
        response = await getCategoryDetailsService(categoryId)
    } catch(error) {
        console.error(error)
        throw error
    }
    return response?.data
})

export const resetCategory = createAsyncThunk('categories/resetCategory', async (categoryId: string) => {
    let response;
    try {
        response = await resetCategoryService(categoryId)
    } catch(error) {
        console.error(error)
        throw error
    }
    return response?.data
})


export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        cleanCreteError: (state: CategoriesType) => {
            state.errorCreateCategories = undefined
            state.errorCategoryDetails = undefined
            state.errorEditCategories = undefined
        },
        cleanEditError: (state: CategoriesType) => {
            state.errorEditCategories = undefined
        },
        cleanCategoryDetails: (state: CategoriesType) => {
            state.categoryDetails.categoryName = ""
            state.categoryDetails.categoryId = ""
            state.categoryDetails.userId = ""
            state.categoryDetails.categoryType = ""
        },
        cleanDeleteCategoriesError: (state: CategoriesType) => {
            state.errorDelete = undefined
        },
        cleanCategoryDetailsError: (state: CategoriesType) => {
            state.errorCategoryDetails = undefined
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCategories.pending, (state: CategoriesType) => {
                state.errorGetCategories = undefined
                state.loadingCategories = true
            })
            .addCase(getAllCategories.fulfilled, (state: CategoriesType, action) => {
                state.loadingCategories = false
                state.counts = action.payload.count
                const pages = Math.ceil(action.payload.count / 20)
                state.pages = action.payload.count / 20 < 0 ? 2 : pages
                const allCategories: CategoryType[] = []
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                action.payload.results.forEach((category: any) => {
                    const categoryObject = {
                        categoryName: category.name,
                        categoryId: category.id,
                        userId: category.user.id,
                        sedToMe: category.send_to_me,
                        aiCreated: category.ai_created,
                        marketAccessible: category.market_accessible,
                        marketApprovement: category.market_approvement,
                        adopted: category.adopted,
                        cardsLength: category.cards_len,
                        learnedCardsVerbalLength: category.learned_cards_verbal_len,
                        learnedCardsTypingLength: category.learned_cards_typing_len,
                        totalGuestsVerbal: category.total_guesses_score,
                        totalNotGuestsVerbal: category.total_incorrect_guesses_score,
                        totalGuestsTyping: category.total_guesses_score_typing,
                        totalNotGuestsTyping: category.total_incorrect_guesses_score_typing,
                        categoryType: category.type_of_cards
                    }
                    allCategories.push(categoryObject)
                })
                state.allCategories = allCategories
            })
            .addCase(getAllCategories.rejected, (state: CategoriesType, action) => {
                state.loadingCategories = false
                state.errorGetCategories = action.error.message
            })
        builder
            .addCase(getCategoriesKidsPaginated.pending, (state: CategoriesType) => {
                state.errorGetCategories = undefined
                state.loadingCategories = true
            })
            .addCase(getCategoriesKidsPaginated.fulfilled, (state: CategoriesType, action) => {
                state.loadingCategories = false
                state.counts = action.payload.count
                const pages = Math.ceil(action.payload.count / 20)
                state.pages = action.payload.count / 20 < 0 ? 2 : pages
                const allCategories: CategoryType[] = []
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                action.payload.results.forEach((category: any) => {
                    const categoryObject = {
                        categoryName: category.name,
                        categoryId: category.id,
                        userId: category.user.id,
                        sedToMe: category.send_to_me,
                        aiCreated: category.ai_created,
                        marketAccessible: category.market_accessible,
                        marketApprovement: category.market_approvement,
                        cardsLength: category.cards_len,
                        learnedCardsVerbalLength: 0,
                        learnedCardsTypingLength: 0,
                        totalGuestsVerbal: 0,
                        totalNotGuestsVerbal: 0,
                        totalGuestsTyping: 0,
                        totalNotGuestsTyping: 0,
                        categoryType: category.type_of_cards
                    }
                    allCategories.push(categoryObject)
                })
                state.allCategories = allCategories
            })
            .addCase(getCategoriesKidsPaginated.rejected, (state: CategoriesType, action) => {
                state.loadingCategories = false
                state.errorGetCategories = action.error.message
            })
        builder
            .addCase(getAllCategoriesNoPaginate.pending, (state: CategoriesType) => {
                state.errorGetCategories = undefined
                state.loadingCategories = true
            })
            .addCase(getAllCategoriesNoPaginate.fulfilled, (state: CategoriesType, action) => {
                state.loadingCategories = false
                const allCategories: Pick <CategoryType, "categoryName" | "categoryId" | "userId" | "sedToMe" | "marketAccessible" | "marketApprovement" | "adopted" | "categoryType">[] = []
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                action.payload.forEach((category: any) => {
                    const categoryObject = {
                        categoryName: category.name,
                        categoryId: category.id,
                        userId: category.user.id,
                        sedToMe: category.send_to_me,
                        marketAccessible: category.market_accessible,
                        marketApprovement: category.market_approvement,
                        adopted: category.adopted,                        
                        categoryType: category.type_of_cards
                    }
                    allCategories.push(categoryObject)
                })
                state.allCategoriesNoPaginate = allCategories
            })
            .addCase(getAllCategoriesNoPaginate.rejected, (state: CategoriesType, action) => {
                state.loadingCategories = false
                state.errorGetCategories = action.error.message
            })
        builder
            .addCase(getAllKidsCategoriesNoPaginate.pending, (state: CategoriesType) => {
                state.errorGetCategories = undefined
                state.loadingCategories = true
            })
            .addCase(getAllKidsCategoriesNoPaginate.fulfilled, (state: CategoriesType, action) => {
                state.loadingCategories = false
                const allCategories: Pick <CategoryType, "categoryName" | "categoryId" | "userId" | "sedToMe" | "marketAccessible" | "marketApprovement" | "adopted" | "categoryType">[] = []
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                action.payload.forEach((category: any) => {
                    const categoryObject = {
                        categoryName: category.name,
                        categoryId: category.id,
                        userId: category.user.id,
                        sedToMe: category.send_to_me,
                        marketAccessible: category.market_accessible,
                        marketApprovement: category.market_approvement,
                        adopted: category.adopted,                        
                        categoryType: category.type_of_cards
                    }
                    allCategories.push(categoryObject)
                })
                state.allCategoriesNoPaginate = allCategories
            })
            .addCase(getAllKidsCategoriesNoPaginate.rejected, (state: CategoriesType, action) => {
                state.loadingCategories = false
                state.errorGetCategories = action.error.message
            })
        builder
            .addCase(createNewCategory.pending, (state: CategoriesType) => {
                state.errorCreateCategories = undefined
                state.loadingCreateCategory = true
            })
            .addCase(createNewCategory.fulfilled, (state: CategoriesType) => {
                state.loadingCreateCategory = false
            })
            .addCase(createNewCategory.rejected, (state: CategoriesType, action) => {
                state.loadingCreateCategory = false
                state.errorCreateCategories = action.error.message
            }),
        builder
            .addCase(editCategory.pending, (state: CategoriesType) => {
                state.errorEditCategories = undefined
                state.loadingEditCategory = true
            })
            .addCase(editCategory.fulfilled, (state: CategoriesType) => {
                state.loadingEditCategory = false
            })
            .addCase(editCategory.rejected, (state: CategoriesType, action) => {
                state.loadingEditCategory = false
                state.errorEditCategories = action.error.message
            })
        builder
            .addCase(getCategory.pending, (state: CategoriesType) => {
                state.errorCategoryDetails  = undefined
                state.loadingCategoryDetails = true
            })
            .addCase(getCategory.fulfilled, (state: CategoriesType, action) => {
                state.loadingCategoryDetails = false
                const parseObject = {
                    categoryName: action.payload.name,
                    categoryId: action.payload.id,
                    userId: action.payload.user,
                    sedToMe: action.payload.send_to_me,
                    marketAccessible: action.payload.market_accessible,
                    marketApprovement: action.payload.market_approvement,
                    adopted: action.payload.adopted,
                    cardsLength: action.payload.cards_len,
                    learnedCardsVerbalLength: action.payload.learned_cards_verbal_len,
                    learnedCardsTypingLength: action.payload.learned_cards_typing_len,
                    totalGuestsVerbal: action.payload.total_guesses_score,
                    totalNotGuestsVerbal: action.payload.total_incorrect_guesses_score,
                    totalGuestsTyping: action.payload.total_guesses_score_typing,
                    totalNotGuestsTyping: action.payload.total_incorrect_guesses_score_typing,
                    categoryType: action.payload.type_of_cards
                    
                }
                state.categoryDetails = parseObject
            })
            .addCase(getCategory.rejected, (state: CategoriesType, action) => {
                state.loadingCategoryDetails = false
                state.errorCategoryDetails = action.error.message
            }),
        builder
            .addCase(resetCategory.pending, (state: CategoriesType) => {
                state.errorCategoryDetails  = undefined
                state.loadingCategoryDetails = true
            })
            .addCase(resetCategory.fulfilled, (state: CategoriesType) => {
                state.loadingCategoryDetails = false
            })
            .addCase(resetCategory.rejected, (state: CategoriesType, action) => {
                state.loadingCategoryDetails = false
                state.errorCategoryDetails = action.error.message
            }),
        builder
            .addCase(deleteCategory.pending, (state: CategoriesType) => {
                state.errorDelete = undefined
                state.loadingDelete = true
            })
            .addCase(deleteCategory.fulfilled, (state: CategoriesType) => {
                state.loadingDelete = false
            })
            .addCase(deleteCategory.rejected, (state: CategoriesType, action) => {
                state.loadingDelete = false
                state.errorDelete = action.error.message
            })
    }
})

export const actions = categoriesSlice.actions;

export const allCategoriesSelector = (state: RootState) => state[categoriesSlice.name];