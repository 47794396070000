import { BASE_URL } from "../common/constants";
import axios, { AxiosResponse } from "axios";
import { KidsFlashCardCreateEdit } from '../common/card-model';
import { withTokenCheck } from "./userAxiosServices"

const getKidsCardsByCategoryPaginated = async (categoryId: string, currentPage: number, searchQuery: string) => {
  const response = axios.get(BASE_URL + `card/card-picture-kids-paginated/`, {
    params: { 
      category_id: categoryId, 
      page: currentPage, 
      search_value: searchQuery
    },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getKidsCardsByCategoryPaginatedService = withTokenCheck(getKidsCardsByCategoryPaginated)

const getKidsCardsByCategory = async (categoryId: string) => {
  const response = axios.get(BASE_URL + `card/card-picture-kids/`, {
    params: { category_id: categoryId },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getKidsCardsByCategoryService = withTokenCheck(getKidsCardsByCategory)

const getKidsCardsDetails = async (cardId: string) => {
  const response = axios.get(BASE_URL + `card/card-picture-kids/${cardId}/`, {
    
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const getKidsCardsDetailsService = withTokenCheck(getKidsCardsDetails)

const createKidsCard = async ({
  sideOne,
  sideTwo,
  categoryId,
  language,
}: KidsFlashCardCreateEdit): Promise<AxiosResponse<any, any>> => {
  let response
  if (sideOne && sideTwo && language) {
    const formData = new FormData();
    formData.append('first_side', sideOne);
    formData.append('second_side', sideTwo);
    formData.append('category', categoryId);
    formData.append('language', language);
    try {
      response = axios.post(BASE_URL + 'card/card-picture-kids/', formData, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
          'content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  if (!response) {
    // If response is still undefined, create a placeholder response with required properties
    response = {
      data: undefined,
      status: 500, // You may want to adjust the status code based on your needs
      statusText: 'Internal Server Error',
      headers: {},
      config: {},
    };
  }
  return response as AxiosResponse<any, any>;
}

export const createKidsCardService = withTokenCheck(createKidsCard)

const editKidsCard = async ({
  sideOne,
  sideTwo,
  categoryId,
  language,
  id
}: KidsFlashCardCreateEdit): Promise<AxiosResponse<any, any>> => {
  let response
  if (sideTwo && language && id && categoryId) {
    const formData = new FormData();
    formData.append('second_side', sideTwo);
    formData.append('category', categoryId);
    formData.append('language', language);
    formData.append('id', id);

    // Append 'first_side' to formData only if sideOne is not null
    if (sideOne !== null) {
      formData.append('first_side', sideOne);
    }
    try {
      console.log(formData);
      response = axios.put(BASE_URL + `card/card-picture-kids/${id}/`, formData, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
        }
      });
    } catch (error) {
      console.log(error)
      throw error
    }
    if (!response) {
      // If response is still undefined, create a placeholder response with required properties
      response = {
        data: undefined,
        status: 500, // You may want to adjust the status code based on your needs
        statusText: 'Internal Server Error',
        headers: {},
        config: {},
      };
    }
  }
  return response as AxiosResponse<any, any>;
}

export const editKidsCardService = withTokenCheck(editKidsCard);

const deleteKidsCard = async (cardId: string) => {
  const response = axios.delete(BASE_URL + `card/card-picture-kids/${cardId}/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const deleteKidsCardService = withTokenCheck(deleteKidsCard)

const listenKidsCard = async ({
  id,
  categoryId,
  sideOne,
  sideTwo,
	language
}: KidsFlashCardCreateEdit) => {
  const body = {
    category: categoryId,
    first_side: sideOne,
    second_side: sideTwo,
		language: language
  }
  const response = axios.put(BASE_URL + `card/card-picture-kids/${id}/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      'content-Type': 'multipart/form-data'
    }
  });
  return response;
}

export const listenKidsCardService = withTokenCheck(listenKidsCard)